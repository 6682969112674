.postlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.postlist-headerTitle {
  margin-bottom: 0;
  width: 100%;
  float: none;
  font-size: 28px;
  line-height: 1.2;

  @include mq(xm-small) {
    float: left;
    width: auto;
  }
}

.postlist-header label {
  margin-top: 12px;
  margin-bottom: 8px;
  font-family: var(--font-family-secondary);
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  letter-spacing: .3px;

  @include mq(xm-small) {
    margin-right: 16px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: right;

    .rtl & {
      margin-right: 0;
      margin-left: 16px;
    }
  }
}

.postlist-sort {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 0;
  width: 100%;
  float: none;

  @include mq(xm-small) {
    float: right;
    width: auto;
  }
}

.postlist-sort .element-select {
  margin-right: 0;
  margin-left: 0;
  width: 100%;

  @include mq(xm-small) {
    margin-right: 0;
    width: auto;
  }
}
