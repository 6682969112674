.logo {
  display: block;
  height: 60px;
  max-width: 180px;

  @include mq(small) {
    height: 72px;
  }
}

.logo:empty {
  @include icon(5px, 82px, 107px, 72px);
  pointer-events: unset;
  width: 180px;
  background-color: $color-primary;
}

.logo-logoType,
.logo-logoMark {
  height: 100%;
  max-height: 100% !important;
}

.logo-logoType {
  display: none;

  @include mq(small) {
    display: block;
  }
}

.logo-logoMark {
  display: block;

  @include mq(small) {
    display: none;
  }
}

.has-many-items .logo-logoType {
  display: none;

  @include mq(1480px) {
    display: block;
  }
}

.has-many-items .logo-logoMark {
  display: block;
  width: 60px;
  max-width: 60px;

  @include mq(small) {
    width: 72px;
    max-width: 72px;
  }

  @include mq(1480px) {
    display: none;
  }
}
