.actionBlock {
  margin: 0;
  max-width: 350px;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  background-color: $color-grey-x-light;
}

.actionBlock.is-centred {
  margin-right: auto;
  margin-left: auto;
}

.actionBlock--wide {
  width: 480px;
  max-width: 100%;
}

.actionBlock-figure {
  position: relative;
  height: 230px;
  overflow: hidden;
}

.actionBlock-figure a {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.66667%;
  }
}

.actionBlock-image {
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, .25);
}

.actionBlock-image img {
  object-fit: cover;
  min-height: 100%;
}

.actionBlock-label {
  position: absolute;
  bottom: 0;
  left: 16px;
  padding: 8px;
  background-color: $color-black;
  color: $color-white;
  font-size: 18px;
  text-transform: uppercase;
}

.actionBlock-content {
  padding: 16px;
  font-size: 24px;
}

.actionBlock-content p {
  margin-bottom: 0;
  min-height: 80px;
}

// these styles may need to be global
.page-template-page-petitions .actionBlock {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.page-template-page-petitions .actionBlock-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}
