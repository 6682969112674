@mixin state-background($colour: $color-black) {
  transition: background-color .3s ease-in-out;

  &:active,
  &:enter {
    background-color: $colour;
  }

  &:focus-within {
    background-color: $colour;
  }
}

@mixin state-foreground($colour: $color-black) {
  transition: color .3s ease-in-out;

  &:active,
  &:enter {
    color: $colour;
  }

  &:focus-within {
    color: $colour;
  }
}
