/**
 * Base button styles.
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Normalize `line-height`. For `input`, it can't be changed from `normal` in Firefox 4+.
 * 4. Prevent button text from being selectable.
 * 5. Make sure `input` will wrap text across multiple lines.
 */

%btn-base {
  -webkit-appearance: none; /* 1 */
  box-sizing: border-box; /* 2 */
  margin: 0;
  display: inline-block;
  font: inherit;
  line-height: normal; /* 3 */
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  overflow: visible;
  user-select: none; /* 4 */
  white-space: normal; /* 5 */
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out;

  &:enter {
    text-decoration: none;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }
}
