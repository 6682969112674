// .customSelect {}

.customSelect-label {
  margin: 0;
  font-size: 16px;
  line-height: 2;
}

.customSelect-wrapper {
  position: relative;
  display: block;
  font-family: var(--font-family-secondary);
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;
  background: transparent;

  .rtl & {
    text-align: right;
  }
}

.customSelect-field,
.customSelect-listItem {
  width: 100%;
  padding: 14px 32px 14px 10px;
  text-align: left;
  text-transform: uppercase;

  .rtl & {
    padding: 14px 10px 14px 32px;
    text-align: right;
  }
}

.customSelect-wrapper,
.customSelect-field {
  min-width: 250px;
}

.customSelect-field {
  appearance: none;
  position: relative;
  height: 100%;
  font-weight: bold;
  border: 1px solid $color-black;
  background: transparent;

  &::after {
    @include icon(271px, 215px, 16px, 16px);
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    display: inline-block;
    content: "";

    .rtl & {
      right: unset;
      left: 10px;
    }
  }

  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
  }
}

.customSelect-fieldValue {
  font-family: var(--font-family-secondary);
}

.customSelect-list {
  position: absolute;
  top: calc(100% - 1px);
  z-index: 10;
  visibility: hidden;
  margin: 0;
  width: 100%;
  max-height: 270px;
  overflow-y: scroll;
  list-style: none;
  border: 1px solid $color-black;
  background-color: $color-white;
  color: $color-black;
}

.customSelect--listShow {
  visibility: visible;
}

.customSelect-listItem {
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary;
  }
}

.customSelect--selected {
  background-color: $color-primary-state;
}

.section--textWhite select,
.section--textWhite .customSelect-field {
  color: $color-white;
}

.section--textWhite .customSelect-field {
  border-color: $color-white;

  &::after {
    background-position: -271px -131px;
  }
}

.download-block .customSelect-field {
  background-color: $color-white;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mq($until: x-small) {
    min-width: auto;
  }
}

.section--textWhite .download-block .customSelect-field {
  background-color: unset;
}

.download-block .customSelect-wrapper {
  @include mq($until: x-small) {
    min-width: auto;
    width: 100% !important;
  }
}

.download-block.aligncenter {
  text-align: center;

  // @include mq($until: x-small) {
  //   min-width: auto;
  //   width: 100% !important;
  // }
}
