.js .wooccm-field-country .woocommerce-input-wrapper,
.js .wooccm-type-select .woocommerce-input-wrapper,
.element-select {
  position: relative;

  .news-sidebar & {
    display: inline-block;
  }
}

.js .wooccm-field-country .woocommerce-input-wrapper::after,
.js .wooccm-type-select .woocommerce-input-wrapper::after,
.element-select::after {
  position: absolute;
  top: calc(50% - 8px);
  right: 10px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("../images/sprite.svg");
  background-repeat: no-repeat;
  background-size: 313px 300px;
  background-position: -271px -215px;
  pointer-events: none;

  .rtl & {
    right: unset;
    left: 10px;
  }
}

%select {
  margin-right: 0 !important;
  padding: 12px 32px 12px 10px;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-family-secondary);
  appearance: none;
  text-transform: uppercase;
  background-color: $color-white;
  border: 1px solid $color-black;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;

  .rtl & {
    padding-right: 10px;
    padding-left: 32px;
  }
}

.js .wooccm-field-country .woocommerce-input-wrapper select,
.js .wooccm-type-select .woocommerce-input-wrapper select,
.element-select select {
  @extend %select;
}

.js .wooccm-field-country .woocommerce-input-wrapper select,
.js .wooccm-type-select .woocommerce-input-wrapper select,
.element-select select::-ms-expand {
  display: none;
}

.element-select--inverse::after {
  background-position: -271px -131px;
}

.element-select--inverse select {
  background: $color-black;
  color: $color-white;
}
