.page-headerButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 60px;
  height: 60px;
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  cursor: pointer;

  @include mq(small) {
    width: 72px;
    height: 72px;
  }
}

.page-headerButtonText {
  display: none;
  margin-right: 5px;

  .rtl {
    margin-right: 0;
    margin-left: 5px;
  }
}

.page-headerButton span {
  pointer-events: none;
}
