.page-footer {
  padding: 28px 20px;
  background-color: $color-black;
  color: $color-white;
}

.page-footer a {
  display: inline-block;
  color: $color-white;
  font-size: 12px;
  font-family: sans-serif;

  &.btn--white {
    color: $color-black;
  }
}

.page-footer a:enter {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.page-footerSections > li > a:first-of-type {
  margin-bottom: 24px;
  padding-bottom: 12px;
  font-size: 21px;
  text-transform: uppercase;
  color: $color-grey-md-light;
  font-family: var(--font-family-secondary);
  border-bottom: 1px solid $color-black-mid-dark;
  font-weight: bold;

  &:enter {
    text-decoration: none;
    cursor: initial;
  }
}

.page-footerSections {
  @include flexy-wrapper;
  list-style: none;
  margin: 0;
}

.page-footerSections > li {
  flex: 1 1 100%;
  margin-top: 24px;

  @include mq(x-small) {
    @include flexy-column(6);
    flex-grow: 1;
    max-width: none;

    &:nth-child(2) {
      margin-top: 0;
    }
  }

  @include mq(small) {
    @include flexy-column(3);
    flex-grow: 1;
    margin-top: 0;
    max-width: none;
  }

  @include mq(large) {
    @include flexy-column(2);
    flex-grow: 1;
    max-width: none;
  }
}

.page-footerSections > li:first-of-type {
  margin-top: 0;
}

.page-footerSections li.page-footerSection--large {
  @include flexy-column(12);
  margin-top: 24px;

  @include mq(small) {
    @include flexy-column(6);
    margin-top: 0;
  }

  @include mq(small, large) {
    &:nth-child(n + 2):not(:nth-child(3)) {
      margin-top: 24px;
    }
  }

  @include mq(large) {
    @include flexy-column(4);
    margin-top: 0;
  }
}

.page-footerSection .btn:active,
.page-footerSection .btn:enter {
  text-decoration: none;
}

.page-footerSection p {
  font-size: 12px;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.page-footerSections li ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

.page-footerSections li ul li + li {
  margin-top: 12px;
}

.page-footerSections a {
  display: block;
}

.page-footerSections a.btn {
  display: inline-block;
}

.page-footerSiteSelection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-footerSiteSelection li + li {
  margin-top: 4px;
}

.page-footerSiteSelection a {
  display: inline-block;
  padding: 4px 8px;
  color: $color-white;
  font-family: var(--font-family-secondary);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.page-footerSiteSelection a:enter,
.page-footerSiteSelection .is-selected a {
  background-color: $color-black-x-dark;
}

.page-footerSections:empty + .page-footerBottom {
  padding-top: 0;
  border: none;
}

.page-footerBottom {
  @include flexy-wrapper;
  flex-wrap: wrap-reverse;
  margin-top: 24px;
  padding-top: 48px;
  border-top: 1px solid $color-black-mid-dark;
}

.page-footerBottomHalf {
  @include flexy-column(12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq(large) {
    @include flexy-column(6);
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.page-footerBottomHalf + .page-footerBottomHalf {
  margin-left: auto;
  text-align: right;
}

.page-footerBottomNav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  line-height: 1.5;
  font-family: sans-serif;
}

.page-footerBottomNav li + li {
  margin-left: 12px;

  .rtl & {
    margin-left: 0;
    margin-right: 12px;
  }
}

.page-footerBottomNav li + li::before {
  @include mq(small) {
    content: "|";
    display: inline-block;
    padding-right: 12px;

    .rtl & {
      padding-right: 0;
      padding-left: 12px;
    }
  }
}

.page-footerBottomNav a {
  color: $color-white;
}

.page-footerCopyright {
  font-size: 12px;
  line-height: 1.5;
  color: rgba($color-white, .7);
  padding-top: 8px;
}

.page-footerSocialContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @include mq(large) {
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.page-footerBottomTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: $color-white;
  margin-bottom: 12px;

  @include mq(large) {
    margin-bottom: 0;
  }
}

.page-footerSocial {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  margin-left: 18px;
  list-style: none;

  .rtl & {
    margin-right: 18px;
    margin-left: 0;
  }
}

.page-footerSocial a {
  @include state-background(lighten($color-black, 20%));
  display: block;
}

.page-footerSocial a span {
  padding: 10px;
  width: 40px;
  height: 40px;
}

.page-footer .page-footerSocial a:hover, .page-footer .page-footerSocial a:focus {
  text-decoration: none;
}
