.page-nav,
.page-nav > ul {
  height: 100%;
}

.page-nav ul {
  margin: 0;
  list-style: none;
}

.page-nav > ul {
  display: inline-flex;
  align-items: stretch;
}

.page-nav .menu-item {
  display: block;
  white-space: nowrap;
}

.page-nav .menu-item a {
  display: inline-flex;
  align-items: center;
  padding: 4px 24px;
  width: 100%;
  height: 100%;
}

.has-many-items .page-nav .menu-item a {
  padding: 4px 20px;

  @include mq(xx-large) {
    padding: 4px 24px;
  }
}

.page-nav .sub-menu {
  position: absolute;
  z-index: 2;
  top: 100%;
  display: none;
  width: 300px;
  background-color: $color-grey-light;
  color: $color-black;
}

.page-nav .sub-menu .menu-item {
  height: 72px;
}

.page-nav .sub-menu .menu-item:hover,
.page-nav .sub-menu .menu-item:active,
.page-nav .sub-menu .menu-item:focus {
  background-color: $color-black;
  color: $color-white;
}

.page-nav .sub-menu .menu-item span {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.page-nav .menu-item-has-children {
  position: relative;
}

.page-nav .menu-item-has-children:hover .sub-menu,
.page-nav .menu-item-has-children:active .sub-menu,
.page-nav .menu-item-has-children:focus .sub-menu {
  display: block;
}

.page-nav .menu-item-has-children:focus-within .sub-menu {
  display: block;
}

.page-nav--main {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  .rtl & {
    margin-right: auto;
    margin-left: 0;
  }
}

.page-nav--main ul {
  display: none;

  @include mq(xm-large) {
    display: flex;
  }
}

.page-nav--left .site-separator {
  border-right: 1px solid $color-grey-md-light;
  height: 30px;
  align-self: center;
}

.is-shaded .page-nav--left .site-separator {
  border-right-color: $color-white;
}
