.page-header.is-shaded {
  background-color: $color-grey-mid-dark;
  color: $color-white;
}

.page-header.is-shaded .menu-item:hover,
.page-header.is-shaded .menu-item:active,
.page-header.is-shaded .menu-item:focus,
.page-header.is-shaded .page-headerButton:hover,
.page-header.is-shaded .page-headerButton:active,
.page-header.is-shaded .page-headerButton:focus {
  background-color: $color-black;
}

.page-header.is-shaded .page-nav--main .current-menu-item,
.page-header.is-shaded .page-nav--main .current-menu-ancestor {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-shaded .burger span {
  background-color: $color-white;
}

.page-header.is-shaded .burger:hover,
.page-header.is-shaded .burger:active,
.page-header.is-shaded .burger:focus {
  background-color: $color-black;

  span {
    background-color: $color-white;
  }
}

.page-header.is-shaded .icon-cart {
  @extend %icon-cart-light;
}

.page-header.is-shaded .icon-search {
  @extend %icon-search-light;
}
