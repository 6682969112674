.page-header {
  position: sticky;
  top: -100%;
  z-index: 11;
  height: 60px;
  font-size: 16px;
  color: $color-white;
  transition: top .5s ease-in-out;

  @include mq(small) {
    height: 72px;
  }
}

.page-header.has-many-items {
  font-size: 15px;

  @include mq(xx-large) {
    font-size: 16px;
  }
}

.page-header .container {
  padding: 0;
}

.page-header .container,
.page-headerItems {
  height: 100%;
}

.page-header.stick {
  top: 0;

  .admin-bar & {
    top: 32px;
  }
}

.page-headerItems {
  display: flex;
  align-items: flex-start;
}

.page-header a {
  color: inherit;
  transition: unset;
}

.page-header .btn {
  display: flex;
  align-items: center;
  color: $color-black;
  font-size: map-get($mobile-header-sizes, "h4");
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.page-header .menu-item {
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}
