.article-sidebar .callToAction,
.callToAction--small {
  padding: 20px;
}

.article-sidebar .callToAction .callToAction-preHeading,
.callToAction--small .callToAction-preHeading {
  font-size: 21px;
  margin-bottom: 20px;
}

.article-sidebar .callToAction .callToAction-heading,
.callToAction--small .callToAction-heading {
  font-size: 30px;
  margin-bottom: 20px;
}

.article-sidebar .callToAction .callToAction-content,
.callToAction--small .callToAction-content {
  font-size: 16px;
  margin-bottom: 20px;
}
