.page-template-without-sidebar main .wp-embed-responsive,
.page-template-without-sidebar main .wp-block-embed,
.post-template-without-sidebar main .wp-embed-responsive,
.post-template-without-sidebar main .wp-block-embed {
  margin-right: auto;
  margin-left: auto;
  max-width: 850px;
}

.page-template-without-sidebar main .wp-embed-responsive .wp-block-embed,
.post-template-without-sidebar main .wp-embed-responsive .wp-block-embed {
  margin-right: unset;
  margin-left: unset;
  max-width: unset;
}

.wp-block-embed.aligncenter {
  text-align: center;
}

.wp-block-embed.alignright {
  display: flex;
  justify-content: flex-end;

  .rtl & {
    justify-content: flex-start;
  }
}
