.post {
  position: relative;
  width: 100%;
}

@include mq($until: x-small) {
  .post + .post {
    margin-top: flexy-gutter();
  }
}

@include mq(x-small) {
  .post {
    min-height: 460px;
  }

  .post + .post {
    margin-left: flexy-gutter();

    .rtl & {
      margin-right: flexy-gutter();
      margin-left: 0;
    }
  }

  .post:nth-child(3n) {
    margin-left: flexy-gutter();
  }
}

@include mq(x-small, medium) {
  .post {
    @include flexy-grid(2, flexy-gutter());
  }

  .post:nth-child(2n + 3) {
    margin-left: 0;

    .rtl & {
      margin-right: 0;
    }
  }

  .post:nth-child(n + 3) {
    margin-top: flexy-gutter();
  }
}

@include mq(medium, large) {
  .post {
    @include flexy-grid(3, flexy-gutter());
  }

  .post:nth-child(3n + 4) {
    margin-left: 0;

    .rtl & {
      margin-right: 0;
    }
  }

  .post:nth-child(n + 4) {
    margin-top: flexy-gutter();
  }

  .has-subcategories .post {
    @include flexy-grid(2, flexy-gutter());
  }

  .has-subcategories .post:nth-child(n + 3) {
    margin-top: flexy-gutter();
  }

  .has-subcategories .post:nth-child(3n + 4) {
    margin-left: flexy-gutter();

    .rtl & {
      margin-right: flexy-gutter();
    }
  }

  .has-subcategories .post:nth-child(2n + 3) {
    margin-left: 0;

    .rtl & {
      margin-right: 0;
    }
  }
}

@include mq(large) {
  .post {
    @include flexy-grid(4, flexy-gutter());
  }

  .has-subcategories .post {
    @include flexy-grid(3, flexy-gutter());
  }

  .has-subcategories .post:nth-child(n + 4) {
    margin-top: flexy-gutter();
  }

  .has-subcategories .post:nth-child(4n + 5) {
    margin-left: flexy-gutter();

    .rtl & {
      margin-right: flexy-gutter();
    }
  }

  .has-subcategories .post:nth-child(3n + 4) {
    margin-left: 0;

    .rtl & {
      margin-right: 0;
    }
  }

  .post:nth-child(4n + 5) {
    margin-left: 0;

    .rtl & {
      margin-right: 0;
    }
  }

  .post:nth-child(n + 5) {
    margin-top: flexy-gutter();
  }
}

.post-header {
  max-width: 100%;
}

.post-content {
  width: 100%;
  padding: 12px;
}

.post-content .post-content {
  padding: 0;
}

.post-category {
  @include state-background(lighten($color-black, 20%));
  display: inline-block;
  padding: 4px 8px;
  color: $color-white;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  background-color: $color-black;
}

.post-meta {
  display: block;
  padding: 8px 8px 0;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  font-size: 14px;
  color: $color-grey-mid-dark;
  background-color: $color-white;
}

.post-title {
  padding: 0 8px;
  margin-bottom: 0;
  font-size: 28px;
}

.post-title a {
  color: $color-black;
}

.post-title span {
  background-color: $color-white;
  box-shadow: -8px 0 0 0 $color-white, 8px 0 0 0 $color-white;
  box-decoration-break: clone;
  line-height: 1.2;
}

.postImage--full {
  position: relative;
  display: flex;
  background-size: cover;
  background-position: center;
}

.postImage--full .post-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.postImage--none,
.postImage--small {
  display: flex;
  flex-direction: column;
}

.postImage--none .post-content,
.postImage--small .post-content {
  position: relative;
  background-color: $color-white;
  flex: 1 1 auto;
}

.postImage--small .post-category {
  position: absolute;
  bottom: 100%;
  left: 12px;

  .rtl & {
    right: 12px;
    left: unset;
  }
}

.postImage--none .post-title,
.postImage--small .post-title {
  margin-top: 12px;
  padding: 0;
}

.postImage--none .post-meta,
.postImage--small .post-meta {
  padding: 0;
}

.postImage--none .post-meta {
  margin-top: 12px;
}

.postImage--none .post-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.postImage--none .post-content::after {
  content: "";
  display: block;
  height: 8px;
  width: 75%;
  margin-top: 12px;
  background-color: $color-black;

  @include mq(x-small) {
    margin-top: auto;
  }
}

.post-figure img {
  display: block;
  width: 100%;
}

.post-content a {
  position: relative;
  z-index: 1;
}

.post .floating-anchor {
  transition: background-color .3s ease-in-out;
}

.post .post-figure img {
  backface-visibility: hidden;
  transition: opacity .3s ease-in-out;
}

.post-figure {
  position: relative;
  background-color: $color-grey-x-dark;
  overflow: hidden;
}

.post:enter {
  .floating-anchor {
    background-color: rgba($color-grey-x-dark, .2);
  }

  .post-figure img {
    opacity: .6;
  }
}

.post:focus-within {
  .floating-anchor {
    background-color: rgba($color-grey-x-dark, .2);
  }

  .post-figure img {
    opacity: .6;
  }
}

:lang(fa-IR) .post-category {
  display: none;
}
