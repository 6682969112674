@import "./header";
@import "./post";
@import "./post-search";
@import "./pagination";
@import "./categories";

.postlist {
  display: flex;
  flex-wrap: wrap;
}
