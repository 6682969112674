.single-petition .article-container {
  flex-wrap: wrap;
}

.single-petition .article-container > .section:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.single-petition .article.has-sidebar {
  flex: 1 1;
  width: auto;
  max-width: none;
}

.single-petition .article-sidebar {
  align-items: stretch;
  justify-content: stretch;

  @include mq(medium-sm) {
    margin-left: 0;
  }

  @include mq(large) {
    margin-top: 0 !important;
  }
}

.single-petition .petition-tags {
  list-style-type: none;
  display: flex;
  margin-right: 0;
  margin-left: 0;
}

.single-petition .petition-tags-container {
  width: 100%;
}

.single-petition .tag-li {
  margin-right: 12px;

  :hover {
    text-decoration: underline;
  }
}

.single-petition .tag-li a {
  text-decoration: none;
  font-family: var(--font-family-secondary);

}

.page-template-page-petitions .petition-index-title {
  font-size: 28px;
  margin-bottom: 24px;
}

.page-template-page-petitions .petition-index-btn {
  font-size: 16px;
  position: absolute;
  bottom: 12px;
  width: calc(100% - 30px);
}

.page-template-page-petitions .petition-index-excerpt p {
  text-transform: uppercase;
  font-size: 18px;
}

.page-template-page-petitions .petition-index-excerpt {
  margin-bottom: 12px;
}

.page-template-page-petitions .petition-index-category a {
  color: $color-white;
  background-color: $color-black;
  position: relative;
  left: 5px;
  padding: 4px 8px;
  text-transform: uppercase;
  display: inline-block;

  &::before {
    padding: 0;
    width: auto;
    content: "";
  }
}

.page-template-page-petitions .petitions-figure {
  height: auto;
}

.page-template-page-petitions .petition-index-category {
  position: absolute;
  bottom: 0;
}

.page-template-page-petitions .petitions-item .actionBlock-content p {
  min-height: 0;
}

.page-template-page-petitions .petitions-item .actionBlock-content {
  justify-content: flex-start;
  margin-bottom: 24px;
}

.page-template-page-petitions .petitions-item {
  min-height: 0;
}
