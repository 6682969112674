.page-header.is-transparent-dark {
  background-color: transparent;
  color: $color-black;
}

.page-header.is-transparent-dark.stick {
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
}

.page-header.is-transparent-dark ~ .page-hero {
  margin-top: -72px;
}

.page-header.is-transparent-dark .menu-item:hover,
.page-header.is-transparent-dark .menu-item:active,
.page-header.is-transparent-dark .menu-item:focus,
.page-header.is-transparent-dark .page-headerButton:hover,
.page-header.is-transparent-dark .page-headerButton:active,
.page-header.is-transparent-dark .page-headerButton:focus {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-transparent-dark .page-nav--main .current-menu-item,
.page-header.is-transparent-dark .page-nav--main .current-menu-ancestor {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-transparent-dark .burger span {
  background-color: $color-black;
}

.page-header.is-transparent-dark .burger:hover,
.page-header.is-transparent-dark .burger:active,
.page-header.is-transparent-dark .burger:focus {
  background-color: $color-black;

  span {
    background-color: $color-white;
  }
}

.page-header.page-header.is-transparent-dark .icon-cart {
  @extend %icon-cart-dark;
}

.page-header.page-header.is-transparent-dark .menu-item-cart:hover .icon-cart,
.page-header.page-header.is-transparent-dark .menu-item-cart:active .icon-cart,
.page-header.page-header.is-transparent-dark .menu-item-cart:focus .icon-cart {
  @extend %icon-cart-light;
}

.page-header.is-transparent-dark .icon-search {
  @extend %icon-search-dark;
}

.page-header.is-transparent-dark .page-headerButton:hover .icon-search,
.page-header.is-transparent-dark .page-headerButton:active .icon-search,
.page-header.is-transparent-dark .page-headerButton:focus .icon-search {
  @extend %icon-search-light;
}
