.news-filters {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include mq(medium) {
    flex-direction: row;
  }
}

.postlist-categoriesContainer--archive {
  padding: 20px;
}

.checkbox-dropdown .checkbox-dropdownList {
  position: relative;
}

.checkbox-dropdown.is-active .checkbox-dropdownList {
  position: absolute;
}

.taxonomyArchive-filters > div {
  position: relative;
  margin-bottom: 16px;
  width: 100%;

  @include mq(medium) {
    margin-right: spacing();
    margin-bottom: 0;
  }

  .rtl & {
    @include mq(medium) {
      margin-right: 0;
      margin-left: spacing();
    }
  }
}

.taxonomyArchive-filters .flickity-slider > div {
  width: 23%;
  margin-right: 1%;
}

.taxonomyArchive-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: 0;

  @include mq(medium) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}

.taxonomyArchive-filters .checkbox-dropdown {
  position: relative;
  margin-right: 0 !important;
  padding: 12px 32px 12px 10px;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-family-secondary);
  appearance: none;
  text-transform: uppercase;
  background-color: $color-white;
  border: 1px solid #000;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;

  .rtl & {
    padding-right: 10px;
    padding-left: 32px;
  }

  &::after {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("../images/sprite.svg");
    background-repeat: no-repeat;
    background-size: 313px 300px;
    background-position: -271px -215px;
    pointer-events: none;

    .rtl & {
      right: unset;
      left: 10px;
    }
  }
}

.taxonomyArchive-filters .checkbox-dropdownList {
  display: none;
}

.checkbox-dropdown.is-active + .checkbox-dropdownList {
  position: absolute;
  display: block;
  z-index: 9999;
  margin-left: 0;
  list-style: none;
  background: $color-grey-xx-light;
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
}

.checkbox-dropdown.is-active + .checkbox-dropdownList li {
  padding: 10px;
  border-top: 1px solid $color-grey-light;
}

.checkbox-dropdown.is-active + .checkbox-dropdownList li label {
  display: flex;

  & span {
    margin-left: 16px;

    .rtl & {
      margin-right: 16px;
      margin-left: 0;
    }
  }
}

.clear-filter {
  padding: 10px;
  background-color: $color-grey-xx-light;
  font-family: var(--font-family-secondary);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: spacing();

  &::after {
    content: "x";
    display: inline-block;
    margin-left: 6px;

    .rtl & {
      margin-left: spacing();
      margin-right: 0;
    }
  }
}

.clear-filter--all {
  background-color: $color-grey-sm-light;
}
