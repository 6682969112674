.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12;
  width: calc(100% - 50px);
  max-width: 400px;
  height: 100vh;
  background-color: $color-white;
  border-left: 1px solid $color-grey-light;
  overscroll-behavior: contain;
  backface-visibility: hidden;
  transition: transform .2s ease-in-out;
  transform: translate3d(100%, 0, 0);
}

.mobile-menu-open .mobile-menu {
  transform: translateZ(0);
}

.admin-bar .mobile-menu {
  top: 46px;

  @include mq(wp-adminbar) {
    top: 32px;
  }
}

.mobile-menuHeader {
  display: flex;
  height: 60px;
  border-bottom: 1px solid $color-grey-md-light;

  @include mq(small) {
    height: 72px;
  }
}

.mobile-menuHeader h2 {
  flex-grow: 1;
  margin: 0;
  padding: 13px 24px;
  font-size: map-get($desktop-header-sizes, "h3");
  border-right: 1px solid $color-grey-md-light;

  @include mq(small) {
    padding: 20px 24px;
  }
}

.mobile-menu .page-nav > ul {
  flex-direction: column;
  width: 100%;
}

.mobile-menu .menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid $color-grey-md-light;
  font-size: map-get($desktop-header-sizes, "h6");
}

.mobile-menu .sub-menu .menu-item:last-child {
  border-bottom: none;
}

.mobile-menu .menu-item a {
  display: inline-flex;
  align-items: center;
  padding: 24px;
  height: 60px;
  transition: background-color .2s ease-in-out;

  @include mq(small) {
    height: 72px;
  }
}

.mobile-menu .menu-item a:hover,
.mobile-menu .menu-item a:active,
.mobile-menu .menu-item a:focus {
  background-color: $color-grey-light;
}

.mobile-menu .menu-item a:focus-within {
  background-color: $color-grey-light;
}

.mobile-menu .menu-item-has-children > a {
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  border-right: 1px solid $color-grey-md-light;

  @include mq(small) {
    width: calc(100% - 72px);
    max-width: calc(100% - 72px);
  }
}

.mobile-menu .page-nav .sub-menu {
  position: static;
  display: block;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: $color-grey-light;
  transition: max-height .2s ease-in-out;
}

.mobile-menu .page-nav .menu-item.is-open .sub-menu {
  max-height: 1000px;
}

.mobile-menu .page-nav .sub-menu .menu-item:hover a,
.mobile-menu .page-nav .sub-menu .menu-item:active a,
.mobile-menu .page-nav .sub-menu .menu-item:focus a {
  background-color: $color-black;
  color: $color-white;
}

.mobile-menu .page-headerButton .icon-arrow-down {
  transition: transform .2s ease-in-out;
}

.mobile-menu .menu-item.is-open .page-headerButton .icon-arrow-down {
  transform: rotate(180deg);
}

.mobile-menu .page-headerButton:hover,
.mobile-menu .page-headerButton:active,
.mobile-menu .page-headerButton:focus {
  background-color: $color-grey-light;
}

.mobile-menu .icon-cart {
  @extend %icon-cart-dark;
}

.mobile-menu .icon-arrow-down {
  @extend %icon-arrow-down-dark;
}
