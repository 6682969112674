.bylineContainer {
  border-bottom: solid 1px;
  border-top: solid 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  margin-bottom: 25px;
}

.avatarContainer {
  padding-right: 10px;
}

.authorInfoContainer {
  align-self: center;
  font-size: 14px;
}

.authorTwitter {
  color: $color-blue-mid-light;
  font-family: var(--font-family-primary);
  font-weight: 300;

  &:hover,
  &:active,
  &:focus {
    opacity: .8;
  }

  &::before {
    content: " ";
    display: inline-block;
    transform: scale(.6);
    vertical-align: -20%;

    @include icon(246px, 131px, 20px, 17px);
  }
}

.authorName {
  font-family: var(--font-family-primary);
  font-weight: 300;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
