/**
 * Display-type utilities
 */
.u-block {
  display: block !important;
}

.u-hidden {
  display: none !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-hiddenVisually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.u-inline {
  display: inline !important;
}

/**
 * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
 * inline-block will display at its default size, and not limit its width to
 * 100% of an ancestral container.
 */
.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%; /* 1 */
}

.u-table {
  display: table !important;
}

.u-tableCell {
  display: table-cell !important;
}

.u-tableRow {
  display: table-row !important;
}

.u-flex {
  display: flex !important;
}

.u-inlineFlex {
  display: inline-flex !important;
}

.u-flexWrap {
  flex-wrap: wrap !important;
}

.u-flexJustifyAround {
  justify-content: space-around !important;
}

.u-flexJustifyBetween {
  justify-content: space-between !important;
}

.u-flexJustifyCenter {
  justify-content: center !important;
}

.u-flexJustifyStart {
  justify-content: flex-start !important;
}

.u-flexJustifyEnd {
  justify-content: flex-end !important;
}

.u-flexAlignCenter {
  align-items: center !important;
}

.u-flexAlignStretch {
  align-items: stretch !important;
}

.u-flexAlignStart {
  align-items: flex-start !important;
}

.u-flexAlignEnd {
  align-items: flex-end !important;
}

.u-flexCenter {
  justify-content: center !important;
  align-items: center !important;
}
