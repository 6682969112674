/**
 * Headings.
 */

@include headings(1,  6) {
  color: $heading-color;
  line-height: 1.12;
  font-family: var(--font-family-secondary);
}

@for $i from 1 through 6 {
  h#{$i} {
    font-size: map-get($mobile-header-sizes, h#{$i});

    @include mq(small) {
      font-size: map-get($desktop-header-sizes, h#{$i});
    }
  }
}

p {
  overflow-wrap: break-word;
}

p.has-drop-cap:not(:focus)::first-letter {
  margin-bottom: 30px;
}

p.has-small-font-size {
  font-size: .9rem;
}

p.has-regular-font-size {
  font-size: $base-font-size;
}

p.has-large-font-size {
  font-size: 1.5rem;
}

p.has-larger-font-size {
  font-size: 2rem;
}

small {
  font-size: $milli-size;
}

.page-template-without-sidebar main,
.post-template-without-sidebar main {
  ul,
  ol {
    padding-left: 18px;

    .rtl & {
      padding-right: 18px;
      padding-left: 0;
    }
  }

  h1:not([class]),
  h2:not([class]),
  h3:not([class]),
  h4:not([class]),
  h5:not([class]),
  h6:not([class]),
  p:not([class]),
  p[class*="-font-size"],
  p[class*="has-drop-cap"],
  ul:not([class]),
  ol:not([class]) {
    margin-right: auto;
    margin-left: auto;
    max-width: 850px;
  }

  > h1:not([class]),
  > h2:not([class]),
  > h3:not([class]),
  > h4:not([class]),
  > h5:not([class]),
  > h6:not([class]),
  > p:not([class]),
  > p[class*="-font-size"],
  > p[class*="has-drop-cap"],
  > ul:not([class]),
  > ol:not([class]) {
    padding-right: 16px;
    padding-left: 16px;
  }

  ul.customSelect-list {
    padding-left: 0;

    .rtl & {
      padding-right: 0;
    }
  }

  .wp-block-amnesty-core-link-group ul {
    padding-right: 0;
    padding-left: 0;
  }
}
