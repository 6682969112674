/**
 * Text transforms
 */
.u-textUpper {
  text-transform: uppercase !important;
}

.u-textLower {
  text-transform: lowercase !important;
}

.u-textCaps {
  text-transform: capitalize !important;
}

/**
 * Font weights
 */
.u-textLight {
  font-weight: 300 !important;
}

.u-textNormal {
  font-weight: normal !important;
}

.u-textBold {
  font-weight: bold !important;
}

/**
 * Text alignment
 */
.u-textLeft {
  text-align: left !important;

  .rtl & {
    text-align: right !important;
  }
}

.u-textCenter {
  text-align: center !important;
}

.u-textRight {
  text-align: right !important;

  .rtl & {
    text-align: left !important;
  }
}

.u-textNoWrap {
  white-space: nowrap !important;
}

/**
 * Text truncation.
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */
.u-textTruncate {
  max-width: 100%; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; /* 2 */
}
