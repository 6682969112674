.wp-block-button.is-style-light .wp-block-button__link,
.woocommerce a.button,
.btn--white {
  border: 1px solid $color-black;
  background-color: $color-white;
  color: $color-black;

  &:enter {
    background-color: $color-grey-brand;
  }
}

.wp-block-file__button,
.wp-block-button.is-style-dark .wp-block-button__link,
.btn--dark {
  background: $color-black;
  color: $color-white;
  border: 1px solid $color-black;

  &:enter {
    background-color: $color-grey-mid-dark;
  }
}
