.linkList {
  list-style: none;
  margin-left: 0;
}

.linkList li {
  flex-basis: 100%;
}

.linkList li + li {
  margin-top: 18px;
}

.linkList-item {
  @include state-background(darken($color-grey-light, 10%));
  position: relative;
  padding: 40px 10px;
  width: 100%;
  min-height: 171px;
  background-color: $color-grey-light;
}

.linkList-itemTitle {
  margin-bottom: 12px;
  margin-top: 12px;
  font-family: var(--font-family-secondary);
  font-size: 19px;
  font-weight: normal;
  line-height: 1.2;

  > a {
    color: $color-black;
    text-decoration: none;
  }
}

.linkList-itemMeta {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  background-color: $color-black;
  color: $color-white;
}

.linkList-itemMeta > a {
  color: $color-white;
  text-decoration: none;
}

.postInfo-container {
  display: flex;
  flex-direction: column;
}

.linkList-itemDate {
  display: flex;
  align-items: baseline;
  margin-bottom: 0;
}

.linkList-itemDate .dateDescription {
  font-family: var(--font-family-secondary);
}

.linkList-itemAuthor .authorDescription {
  font-family: var(--font-family-secondary);
}

.linkList-itemDate .dateTerm {
  padding-right: 10px;
}

.linkList-itemAuthor .authorTerm {
  padding-right: 10px;
}

.linkList-itemAuthor {
  display: flex;
  align-items: baseline;
}

.linkList-authorDivider {
  display: inline-flex;
}

.linkList-authorDivider::before {
  content: " ";
  height: 12px;
  width: 1px;
  background: $color-black;
  margin: 10px;
}
