/**
 * Remove extra vertical spacing when nesting lists.
 */
li > ul,
li > ol {
  margin-bottom: 0;
}

ul,
ol {
  margin-left: 18px;
}

.rtl .article-content {
  ul,
  ol {
    margin-right: 18px;
  }
}

.rtl.page-template-without-sidebar main {
  ul,
  ol {
    padding-right: 40px;
  }
}

.rtl.page-template-without-sidebar .wp-block-amnesty-core-block-section {
  ul,
  ol {
    padding-right: 20px !important;
  }
}

.ltr.page-template-without-sidebar main {
  ul,
  ol {
    padding-left: 40px;
  }
}

.ltr.page-template-without-sidebar .wp-block-amnesty-core-block-section {
  ul,
  ol {
    padding-left: 20px !important;
  }
}
