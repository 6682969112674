.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 1 100%;
  max-width: none;
  min-height: 360px;
  padding: 24px;
  background-color: $color-grey-md-light;
  background-position: center;
  background-size: cover;

  @include flexy-grid(1, flexy-gutter());

  @include mq(x-small) {
    @include flexy-grid(2, flexy-gutter());

    .article-sidebar & {
      flex: 1 1 100%;
      max-width: none;
      min-width: 49%;
    }
  }
}

.grid-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: opacity;
  background-color: $color-grey-x-dark;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
}

.grid-item:enter::before {
  opacity: .4;
}

.grid-item:focus-within::before {
  opacity: .4;
}

.grid-item .grid-content {
  max-width: 100%;
}

.grid-item .grid-content a {
  position: relative;
  z-index: 2;
}

.floating-anchor {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.grid-itemMeta {
  padding: 4px 12px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  background-color: $color-black;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color-white;
}

span.grid-itemMeta a {
  color: $color-white;
  text-decoration: none;
}

.grid-itemTitle {
  display: block;
  margin-top: 1px;
  margin-bottom: 0;
  padding: 0 12px;
  max-width: 100%;
  font-size: 24px;
  line-height: 1.2;
  text-decoration: none;
}

h3.grid-itemTitle > span,
h3.grid-itemTitle > a {
  color: $color-black;
  background-color: $color-white;
  box-shadow: -12px 0 0 0 $color-white, 12px 0 0 0 $color-white;
  box-decoration-break: clone;
  text-decoration: none;
  max-width: 100%;
}

.grid-itemContent {
  position: relative;
  display: none;
  overflow: hidden;
  max-width: 100%;
}

.no-js .grid-item:enter .grid-itemContent {
  display: block;
}

.no-js .grid-item:focus-within .grid-itemContent {
  display: block;
}

.grid-itemContent p {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 12px;
  color: $color-black;
  line-height: 1.4;
  font-size: 16px;
  background-color: $color-white;
  max-width: 100%;
}

.grid-itemContent a {
  color: $color-black;
  font-weight: bold;
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
}

.grid {
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
}

.grid-many {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq($from: x-small, $until: medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid .grid-item {
  max-width: 100%;
  width: 100%;
}

.grid-2 {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-5 {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include mq($from: x-small, $until: medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-5 :nth-child(n+3) {
  grid-column: span 2;

  @include mq($from: x-small, $until: medium) {
    grid-column: span 1;
  }
}

.grid-5 :nth-child(-n+2) {
  grid-column: span 3;

  @include mq($from: x-small, $until: medium) {
    grid-column: span 1;
  }
}

.grid-3 {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid-1 .grid-item {
  width: 33%;
}

.grid-6 {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq($from: x-small, $until: medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-6 :nth-child(-n+2) {
  grid-column: span 1;

  @include mq(x-small) {
    grid-column: span 2;
  }

  @include mq($from: x-small, $until: medium) {
    grid-column: span 1;
  }
}

.grid-7 {
  grid-template-columns: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(12, 1fr);
  }

  @include mq($from: x-small, $until: medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-7 :nth-child(-n+3) {
  grid-column: span 1;

  @include mq(x-small) {
    grid-column: span 4;
  }

  @include mq($from: x-small, $until: medium) {
    grid-column: span 1;
  }
}

.grid-7 :nth-child(n+4) {
  grid-column: span 1;

  @include mq(x-small) {
    grid-column: span 3;
  }

  @include mq($from: x-small, $until: medium) {
    grid-column: span 1;
  }
}

.ie .grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ie .grid img {
  width: 500px;
}

.ie .grid .grid-item {
  max-width: 350px;
  width: 350px;
  margin-right: 14px;
  margin-bottom: 14px;
}

.ie .petition-item .petition-itemImage {
  width: 350px;
}

:lang(fa-IR) .grid .grid-content > * {
  line-height: 1.75;
}
