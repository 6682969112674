/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 */
h1, h2, h3, h4, h5, h6, hgroup,
ul, ol, dl,
blockquote, p, address,
table,
fieldset, figure,
pre,
%margin-bottom {
  margin-bottom: spacing();
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol, dd,
%margin-left {
  margin-left: spacing(double);
}
