.article-recipients {
  position: relative;
  margin-bottom: 24px;
}

.article-recipients summary {
  display: inline-flex;
  align-items: center;
  padding: 19px 24px;
  width: 270px;
  max-width: 100%;
  background-color: $color-black;
  color: $color-white;
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.article-recipients summary::marker {
  display: none;
}

.article-recipients summary::after {
  @include icon(271px, 131px, 16px, 16px);
  content: "";
  display: inline-block;
  margin-left: auto;
}

.article-recipients[open] summary::after {
  @include icon(271px, 194px, 16px, 16px);
}

.article-recipients[open] div {
  position: absolute;
  z-index: 1;
  top: 100%;
}

.article-recipients div {
  padding: 20px 24px;
  max-width: 100%;
  min-width: 50%;
  border: solid 1px $color-grey-md-light;
  background-color: $color-grey-light;
}

.article-recipients div p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--font-family-secondary);
  font-size: 18px;
}

.article-recipients div p + p {
  margin-top: 30px;
}
