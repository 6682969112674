.topics-container {
  margin-top: 50px;
}

.topics-container ul {
  display: flex;
  margin-left: 0;
  flex-wrap: wrap;
}

.topics-container li {
  list-style: none;
  margin-right: 24px;
  margin-bottom: 16px;
}

.topics-container li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $color-grey-xx-light;
  padding: 10px;
  transition: background-color .25s;
  font-family: var(--font-family-secondary);
  font-size: 14px;
}

.topics-container li a:hover,
.topics-container li a:focus,
.topics-container li a:active {
  background-color: $color-grey-sm-light;
}

.topics-container h2 {
  font-size: 24px;
  margin-bottom: 12px;
}

.single-post .article-meta > span {
  display: block;
  width: 100%;

  @include mq(xx-small) {
    display: inline-block;
    width: auto;
  }
}
