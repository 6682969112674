.linksWithIcons {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 10px;
  max-width: 456px;
  text-align: center;
}

.linksWithIcons > * {
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;

  & + * {
    margin-top: 20px;

    @include mq(980px) {
      margin-top: 24px;
    }
  }

  .wp-block-columns.has-3-columns .wp-block-column & {
    max-width: 285px;
  }
}

.linksWithIcons-title,
.linksWithIcons-bigtext {
  font-family: var(--font-family-secondary);
}

.linksWithIcons-title {
  font-size: 26px;
  font-weight: bold;
  line-height: 1;

  @include mq(x-small) {
    font-size: 31px;
  }

  .is-style-square & {
    font-size: map-get($desktop-header-sizes, "h6");
    text-transform: uppercase;
  }
}

.linksWithIcons-imageWrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 40%;

  @include mq(medium-sm) {
    max-width: 60%;
  }
}

.linksWithIcons-imageWrapper.is-uncredited .image-caption {
  display: none;
}

.linksWithIcons-imageWrapper.is-large {
  max-width: 55%;

  @include mq(medium-sm) {
    max-width: 75%;
  }
}

.linksWithIcons-imageWrapper.is-small {
  max-width: 25%;

  @include mq(medium-sm) {
    max-width: 45%;
  }
}

.linksWithIcons-imageWrapper.is-xsmall {
  max-width: 64px;
}

.linksWithIcons-image {
  display: block;
  width: 100%;
}

.linksWithIcons-bigtext {
  display: inline-block;
  font-weight: bold;
  line-height: 1em;
  word-wrap: normal;
  color: $color-grey-dark !important;
}

.linksWithIcons-bigtext.has-underline {
  border-bottom: 8px solid $color-grey-dark;
}

.linksWithIcons-group.is-style-square .linksWithIcons {
  border: 1px solid;
  margin: 0 0 spacing(); // margin-bottom is for IE11
  padding-top: spacing();
  padding-bottom: spacing();
  height: 100%;

  & > * + * {
    margin-top: spacing(half);
  }
}

.linksWithIcons-group.is-style-square a.linksWithIcons {
  transition: background .2s ease-in-out;
}

.linksWithIcons-group.is-style-square a.linksWithIcons:hover,
.linksWithIcons-group.is-style-square a.linksWithIcons:active {
  background-color: $color-grey-x-light;
}

.linksWithIcons-group.is-style-square a.linksWithIcons:focus-within {
  background-color: $color-grey-x-light;
}
