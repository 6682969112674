.headerBlock .container {
  padding: 0 flexy-gutter();
  display: flex;
  flex-wrap: wrap;

  @include mq("md-small") {
    flex-wrap: nowrap;
  }
}

.headerBlock .page-heroTitle span {
  line-height: 1.2;
}

.headerBlock .hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerBlock .donation {
  margin-top: 50px;

  @include mq("md-small") {
    margin-top: 0;
  }
}

.headerBlock.headerBlock.page-heroAlignment--centre .hero-content {
  align-items: center;
}

.page-heroAlignment--center .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include mq("md-small") {
    flex-direction: row;
    align-items: flex-start;
  }
}

.page-heroAlignment--left .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include mq("md-small") {
    flex-direction: row;
    align-items: flex-start;
  }

  .rtl & {
    @include mq("md-small") {
      flex-direction: row;
    }
  }
}

.page-heroAlignment--left .container .hero-content {
  align-items: center;

  @include mq("md-small") {
    align-items: flex-start;
  }
}

.page-heroAlignment--right .container .hero-content {
  align-items: center;

  @include mq("md-small") {
    align-items: flex-end;
  }
}

.page-hero .container .hero-content {
  flex-direction: column;
}

.page-heroAlignment--right .container {
  align-content: center;
  flex-direction: column;
  align-items: center;

  @include mq("md-small") {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  .rtl & {
    @include mq("md-small") {
      // this is required because the order is incorrect in the dom order when using right posiiton
      /* stylelint-disable */
      flex-direction: unset;
      flex-direction: row-reverse;
      /* stylelint-enable */
    }
  }
}

.page-heroAlignment--right .container .hero-content,
.page-heroAlignment--left .container .hero-content {
  flex-direction: column;
  position: relative;
  margin-bottom: 5%;
}

.page-heroAlignment--right .donation {
  flex-direction: column;
  position: relative;
}

.page-heroAlignment--center .container .hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 5%;
  margin-right: 0;
}

.page-heroAlignment--center .donation {
  margin-left: 2%;

  @include mq($from: xx-small, $until: large) {
    flex-direction: column;
    position: relative;
  }
}

.page-heroAlignment--left .donation {
  flex-direction: column;
  position: relative;
}

.rtl .page-heroAlignment--right .container .page-heroCta {
  margin-right: auto;
  margin-left: 0;
}

.rtl .page-heroAlignment--center .container .donation {
  @include mq("md-small") {
    margin-right: 2%;
    margin-left: 0;
  }
}

.page-heroAlignment--left .container .donation {
  @include mq("md-small") {
    margin-right: 0;
    margin-left: 2%;
  }

  .rtl & {
    @include mq("md-small") {
      margin-right: 2%;
      margin-left: 0;
    }
  }
}

.headerBlock .content-type-container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1468px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  padding: 0 20px;
  height: 27px;
}

.headerBlock .content-type-container li {
  list-style-type: none;
}

.headerBlock .content-type-container a {
  text-decoration: none;
  font-family: var(--font-family-secondary);
  padding: 5px;
  font-size: 18px;
  color: $color-white;
  background-color: $color-black;
}

.rtl .headerBlock .page-heroTitle span {
  padding: 10px 0;
  line-height: 1.45;
}

.page-heroAlignment--center .container .hero-content.has-donation-block {
  align-items: center;

  @include mq("md-small") {
    align-items: flex-start;
  }
}

.page-heroAlignment--center .container .hero-content .page-heroTitle {
  text-align: center;
}

.page-heroAlignment--left .container .hero-content .page-heroTitle {
  text-align: center;

  @include mq("md-small") {
    text-align: left;
  }
}

.page-heroAlignment--right .container .hero-content .page-heroTitle {
  text-align: center;

  @include mq("md-small") {
    text-align: right;
  }
}

.page-heroAlignment--center .container .hero-content.has-donation-block .page-heroTitle {
  text-align: center;

  @include mq("md-small") {
    text-align: left;
  }
}

.topics-location-container {
  margin-bottom: spacing(half);
}

.topics-location-container ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
}

.topics-location-container li {
  margin-right: spacing(half);
  list-style-type: none;
  font-family: var(--font-family-secondary);
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

.rtl .topics-location-container li {
  margin-right: 0;
  margin-left: spacing(half);
}

:lang(fa-IR) .headerBlock .page-heroTitle span {
  line-height: 1.73;

  @include mq(small) {
    line-height: 1.71;
  }
}

:lang(fa-IR) .rtl .headerBlock .page-heroTitle span {
  padding: 0 0;

  @include mq(small) {
    padding: 10px 0;
    line-height: 2.01;
  }
}

:lang(fa-IR) .headerBlock .tag-li {
  display: none;
}
