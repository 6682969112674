.wp-block-image {
  margin-bottom: 24px;
}

.wp-block-image.alignright {
  float: right;
}

.wp-block-image.alignleft {
  float: left;
}

.wp-block-image [class*="wp-image-"] {
  max-width: 100%;
}

.wp-block-image figcaption {
  color: $color-grey-base;
  font-size: 14px;
  text-align: left;
}

.wp-block-image img[width][height] {
  max-width: 100%;
  height: auto;
}
