.page-header.is-transparent-light {
  background-color: transparent;
  color: $color-white;
}

.page-header.is-transparent-light.stick {
  background-color: $color-white;
  color: $color-black;
  border-bottom: 1px solid $color-grey-light;
}

.page-header.is-transparent-light ~ .page-hero {
  margin-top: -72px;
}

.page-header.is-transparent-light .menu-item:hover,
.page-header.is-transparent-light .menu-item:active,
.page-header.is-transparent-light .menu-item:focus,
.page-header.is-transparent-light .page-headerButton:hover,
.page-header.is-transparent-light .page-headerButton:active,
.page-header.is-transparent-light .page-headerButton:focus {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-transparent-light .page-nav--main .current-menu-item,
.page-header.is-transparent-light .page-nav--main .current-menu-ancestor {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-transparent-light .burger span {
  background-color: $color-white;
}

.page-header.is-transparent-light .burger:hover,
.page-header.is-transparent-light .burger:active,
.page-header.is-transparent-light .burger:focus {
  background-color: $color-white;

  span {
    background-color: $color-black;
  }
}

.page-header.is-transparent-light .icon-cart {
  @extend %icon-cart-light;
}

.page-header.is-transparent-light .icon-search {
  @extend %icon-search-light;
}
