/**
 * Apply box-sizing here, then inherit.
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
html {
  box-sizing: border-box;
}

html.menu-open,
html.mobile-menu-open,
html.search-open,
html.modal-open {
  position: fixed;
  overflow-y: scroll;
  margin-top: 0 !important;
  width: 100%;

  .admin-bar {
    padding-top: 46px;

    @include mq(783px) {
      padding-top: 32px;
    }
  }
}

*, *::after, *::before {
  box-sizing: inherit;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  background-color: rgba($color-black, .25);

  html[class*="-open"] &,
  html.search-active & {
    display: block;
  }
}

/**
 * Page level styles.
 *
 * 1. Use advanced typographic features included in some OpenType fonts. Only enable if
 *    font supports it.
 * 2. Prevent certain mobile browsers from automatically zooming fonts.
 * 3. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
body {
  font-size: $base-font-size;
  font-family: var(--font-family-primary);
  line-height: $line-height-ratio;
  color: $body-color;
  // font-feature-settings: "kern", "liga", "pnum"; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
  -moz-osx-font-smoothing: grayscale;   /* 3 */
  -webkit-font-smoothing: antialiased; /* 3 */
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body.admin-bar {
  min-height: calc(100vh - 46px);

  @include mq(wp-adminbar) {
    min-height: calc(100vh - 32px);
  }
}

body.rtl {
  direction: rtl;
}

main {
  position: relative;
  flex: 1 0 auto;
}

.ie .main {
  flex: 0 0 auto;
}

address {
  font-style: normal;
}

.container {
  display: block;
  width: 100%;
  max-width: $flexy-container;
  margin: 0 auto;
  padding: 0 flexy-gutter();

  @include mq(1490px) {
    padding: 0 $flexy-outer-gutter;
  }
}

.container .container {
  padding: 0;
}

.container--small {
  max-width: 1113px;
}

.only-mobile {
  @include mq(small) {
    display: none !important;
  }
}

.only-desktop {
  @include mq($until: small) {
    display: none !important;
  }
}
