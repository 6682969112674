.download-block {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;

  @include mq(x-small) {
    flex-direction: row;
  }
}

.download-block.alignleft {
  float: none !important;

  @include mq(x-small) {
    justify-content: flex-start;
  }
}

.download-block.aligncenter {
  float: none !important;

  @include mq(x-small) {
    justify-content: center;
    display: flex;
  }
}

.download-block.alignright {
  float: none !important;

  @include mq(x-small) {
    justify-content: flex-end;
  }
}

.download-block .customSelect-wrapper + .btn {
  @include mq(x-small) {
    margin-left: 20px;

    .rtl &,
    [dir="rtl"] & {
      margin-right: 20px;
      margin-left: 0;
    }

    .ltr &,
    [dir="ltr"] & {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.download-block .element-select {
  height: 50px;
}

.download-block .element-select select {
  height: 100%;
  border-radius: 0;
  border: 1px solid $color-black;
}

.btn--download {
  display: inline-flex;
  align-items: baseline;
  margin-top: 24px;

  .article-content & {
    margin-top: 24px;
  }
}

.download-block .btn--download {
  display: inline-flex;

  [dir="rtl"] & {
    flex-direction: row-reverse;
  }

  [dir="ltr"] & {
    flex-direction: row;
  }

  @include mq(x-small) {
    margin-top: 0;
  }
}

.btn--download::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-image: url("../images/icon-download-arrow.svg");
  background-size: contain;
  transform: translateY(2px);

  .rtl & {
    margin-right: 0;
    margin-left: 20px;
  }
}

.btn--primary.btn--download::before,
.btn--white.btn--download::before {
  background-image: url("../images/icon-download-arrow-dark.svg");
}

.customSelect--selected {
  background-color: $color-primary;
}

.customSelect--selected:hover,
.customSelect--selected:active,
.customSelect--selected:focus {
  background-color: $color-primary-state;
}

.customSelect--selected:focus-within {
  background-color: $color-primary-state;
}

.customSelect-listItem {
  white-space: nowrap;

  &:active,
  &:focus {
    background-color: $color-primary;
  }
}

.article-sidebar .customSelect.download-block {
  @include mq(large) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.article-sidebar .download-block .customSelect-wrapper {
  max-width: 100%;
}

.article-sidebar .download-block .btn {
  @include mq(large) {
    margin: 24px 0 0;
  }
}

.wp-block-amnesty-core-block-section .download-block {
  padding: 0 20px;
}
