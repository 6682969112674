.page-hero {
  position: relative;
  padding-top: 111px;
  padding-bottom: 80px;
  background-size: cover;
  background-position: center center;
  color: $color-white;

  @include mq(small) {
    min-height: 710px;
  }
}

.page-header--light ~ .page-hero,
.page-header--shaded ~ .page-hero {
  padding-top: 80px;
}

.page-header--transparent-dark ~ .page-hero,
.page-header--transparent-light ~ .page-hero {
  padding-top: 152px;
}

.page-heroTitle {
  max-width: 840px;
  margin: 0;
  padding: 0 16px;
  font-size: 36px;
  line-height: 1.2;

  @include mq(xx-small) {
    font-size: 48px;
    padding: 0 20px;
  }

  @include mq(small) {
    font-size: 72px;
  }
}

.page-heroTitle span {
  background-color: rgba($color-black, .45);
  color: $color-white;
  box-shadow: 20px 0 0 rgba($color-black, .45), -20px 0 0 rgba($color-black, .45);
  box-decoration-break: clone;
}

p.page-heroContent {
  display: table;
  margin-bottom: 0;
  padding: 10px 16px;
  max-width: 460px;
  font-size: 18px;
  background-color: rgba($color-black, .45);

  @include mq(xx-small) {
    font-size: 24px;
    padding: 10px 20px;
  }
}

.page-heroContent a {
  color: inherit;
  text-decoration: underline;
}

.page-heroCta {
  display: inline-block;
  background-color: rgba($color-black, .45);
  padding: 10px 16px 16px;

  .btn {
    font-size: 24px;
    padding: 10px 14px;

    @include mq(xx-small) {
      font-size: 30px;
      padding: 11px 20px;
    }
  }

  @include mq(xx-small) {
    padding: 10px 20px 20px;
  }
}

.page-heroSize--small {
  @include mq(small) {
    min-height: 450px;
  }
}

.page-heroBackground--dark .page-heroContent,
.page-heroBackground--dark .page-heroCta,
.page-heroBackground--dark .page-heroTitle span {
  background-color: $color-black;
}

.page-heroBackground--dark .page-heroTitle span {
  box-shadow: 16px 0 0 $color-black, -16px 0 0 $color-black;

  @include mq(xx-small) {
    box-shadow: 20px 0 0 $color-black, -20px 0 0 $color-black;
  }
}

.page-heroBackground--light .page-heroContent,
.page-heroBackground--light .page-heroCta,
.page-heroBackground--light .page-heroTitle span {
  background-color: $color-white;
  color: $color-black;
}

.page-heroBackground--light .page-heroTitle span {
  box-shadow: 16px 0 0 $color-white, -16px 0 0 $color-white;

  @include mq(xx-small) {
    box-shadow: 20px 0 0 $color-white, -20px 0 0 $color-white;
  }
}

.page-heroBackground--light .btn {
  border-color: $color-black;
  background-color: $color-black;
  color: $color-white;

  &:enter {
    background-color: rgba($color-black, .8);
    color: $color-white;
  }
}

.page-heroBackground--none .page-heroContent,
.page-heroBackground--none .page-heroCta,
.page-heroBackground--none .page-heroTitle,
.page-heroBackground--none .page-heroTitle span {
  padding-left: 0;
  padding-right: 0;
  color: $color-white;
  background-color: transparent;
}

.page-heroBackground--none .page-heroTitle span {
  box-shadow: none;
}

.page-heroAlignment--centre .hero-content,
.page-heroAlignment--center .hero-content {
  max-width: 840px;
}

.page-hero--video {
  position: relative;
  z-index: 0;
}

.page-hero--video.is-loaded .image-caption {
  display: none;
}

.page-heroVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.page-heroVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);

  @include mq(small) {
    display: block;
  }
}

.play-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 12px;
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent transparent transparent $color-black;
  text-indent: -1000vw;
  overflow: hidden;
}

.page-heroBackground--light .play-icon {
  border-color: transparent transparent transparent $color-white;
}
