.wp-block-amnesty-core-term-list {
  .navigation {
    margin-bottom: 48px;
    text-align: center;

    @include mq(small) {
      text-align: left;
    }
  }

  .navigation button {
    appearance: none;
    border: none;
    margin: 1px;
    width: 60px;
    height: 60px;
    background-color: $color-grey-x-light;
    font-family: var(--font-family-secondary);
    font-size: 28px;
    font-weight: bold;

    &[disabled] {
      color: $color-grey-mid-light;

      &:hover,
      &:active,
      &:focus {
        background-color: $color-grey-x-light;
        color: $color-grey-mid-light;
        cursor: auto;
      }
    }

    &.is-active {
      background-color: $color-black;
      color: $color-white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $color-black;
      color: $color-white;
      transition: background-color .25s, color .25s;
      cursor: pointer;
    }
  }

  .listContainer {
    display: flex;
    align-items: stretch;
  }

  .activeLetter {
    flex-basis: 10%;
    font-family: var(--font-family-secondary);
    font-size: 80px;
    line-height: 1;
  }

  .listItems {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    list-style: none;

    @include mq(x-small) {
      flex-direction: row;
    }
  }

  .listItem {
    display: inline-flex;
    flex: 1 1 25%;
    max-width: 100%;
    margin-bottom: 24px;

    @include mq(x-small) {
      max-width: 25%;
    }
  }

  .listItems a {
    text-decoration: none;
    color: inherit;
  }

  .listItems a:hover,
  .listItems a:active,
  .listItems a:focus {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}
