.wp-block-button {
  margin-right: auto;
  margin-left: auto;
  max-width: 850px;
}

.wp-block-file a.wp-block-file__button,
.wp-block-button .wp-block-button__link,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.btn {
  @extend %btn-base;
  padding: 12px 24px;
  color: $color-black;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: $base-border-radius;
  font-family: var(--font-family-secondary);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;

  &:enter,
  &:active {
    border-color: $color-primary-state;
    background-color: $color-primary-state;
  }
}

.btn.has-icon {
  display: inline-flex;
  align-items: center;
  padding: 13px;
}

.btn.has-icon span[class*="icon-"] {
  &:first-child {
    margin-right: 8px;
  }

  &:last-child {
    margin-left: 8px;
  }

  .rtl & {
    &:first-child {
      margin-left: 8px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

.wp-block-button.is-style-link {
  background-color: transparent !important;
  border: none !important;

  &:enter {
    background-color: transparent !important;
  }
}

.wp-block-button.is-style-link .wp-block-button__link {
  color: inherit;
  border: none;
  background-color: transparent;
  font-family: var(--font-family-primary);
  font-weight: normal;
  text-transform: capitalize;
  text-decoration: underline;
}

.wp-block-button.is-style-link .wp-block-button__link::before {
  @include icon(290px, 200px, 17px, 11px);
  content: " ";
  display: inline-block;
  vertical-align: middle;
}

.rtl .wp-block-button.is-style-link .wp-block-button__link::before {
  transform: scaleX(-1);
}

.section--textWhite .wp-block-button.is-style-link .wp-block-button__link::before {
  @include icon(250px, 238px, 17px, 11px);
  content: " ";
  display: inline-block;
  vertical-align: middle;
}

.section--textWhite .btn {
  color: $color-black;
}
