.wp-block-separator {
  margin: 1.65em auto;
  max-width: 100px;
  border: none;
  border-bottom: 2px solid $color-grey-mid-light;
}

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
  max-width: none;
}

.wp-block-separator.is-style-dots {
  max-width: none;
  height: auto;
  border: none;
  text-align: center;
  line-height: 1;
}

.wp-block-separator.is-style-dots::before {
  content: "\00b7 \00b7 \00b7";
  padding-left: 2em;
  color: $color-black-light;
  font-size: 20px;
  font-family: serif;
  letter-spacing: 2em;
}
