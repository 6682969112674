.grid .petition-item {
  padding: 0;
}

.petition-item {
  background: $color-grey-light;
  display: flex;
  justify-content: flex-start;
}

.petition-item .image-caption {
  display: none;
}

.petition-item .petition-itemImage {
  display: flex;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.petition-item figure {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}

.petition-item .petition-itemImageCaption {
  position: absolute;
  bottom: 0;
  left: 5px;
  background: $color-black;
  color: $color-white;
  padding: 4px 8px;
  text-transform: uppercase;
  font-family: var(--font-family-secondary);

  a {
    color: $color-white;
    text-transform: uppercase;
    font-family: var(--font-family-secondary);
  }
}

.petition-item .petition-item-content {
  width: 100%;
  padding: 14px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.petition-item .petition-itemTitle {
  margin-top: 0;
  font-family: var(--font-family-secondary);
  color: $color-black;
  flex-grow: 1;
}

.petition-item .petition-itemTitle a {
  font-family: var(--font-family-secondary);
  color: $color-black;
  font-weight: bold;
  font-size: 28px;
}

.petition-item .petition-itemExcerpt {
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
  line-height: 1.8;
}

.petition-item .petition-itemCta {
  display: block;
  text-transform: uppercase;
  width: 100%;
}

.petition-layout {
  display: flex;
  flex-wrap: wrap;
}

.petition-layout .petition-item {
  max-width: 330px;
  margin-right: 24px;
  margin-top: 24px;
  padding: 0;
}

.petition-itemImage img {
  width: 100%;
  object-fit: cover;
}

// .grid-many {
//   display: grid;
//   /* grid-auto-flow: column; */
//   grid-template-columns: repeat(4, 1fr);
//   row-gap: 20px;
// }
