a {
  color: $color-black;
  transition: color .3s ease-in-out;
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

.section p > a,
.section li a,
.article-content p > a,
.article-content li a {
  font-weight: bold;
  text-decoration: underline;
}
