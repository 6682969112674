.page-header.is-light {
  background-color: $color-white;
  color: $color-black;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}

.page-header.is-light.stick {
  border-bottom: 1px solid $color-grey-light;
}

.page-header.is-light .menu-item:hover,
.page-header.is-light .menu-item:active,
.page-header.is-light .menu-item:focus,
.page-header.is-light .page-headerButton:hover,
.page-header.is-light .page-headerButton:active,
.page-header.is-light .page-headerButton:focus {
  background-color: $color-grey-light;
  color: $color-black;
}

.page-header.is-light .page-nav--main .current-menu-item,
.page-header.is-light .page-nav--main .current-menu-ancestor {
  background-color: $color-black;
  color: $color-white;
}

.page-header.is-light .burger:hover,
.page-header.is-light .burger:active,
.page-header.is-light .burger:focus {
  background-color: $color-grey-light;

  span {
    background-color: $color-black;
  }
}

.page-header.is-light .icon-cart {
  @extend %icon-cart-dark;
}

.page-header.is-light .page-nav--main .current-menu-item .icon-cart {
  @extend %icon-cart-light;
}

.page-header.is-light .icon-search {
  @extend %icon-search-dark;
}

.page-header.is-light .page-nav--main .current-menu-item .icon-search {
  @extend %icon-search-light;
}
