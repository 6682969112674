.section {
  @include clearfix;
  padding: 20px 0;
  color: $color-black;

  @include mq(medium) {
    padding: 60px 0;
  }
}

.section.is-fullWidth--mobile,
.section.is-fullWidth--mobile .container {
  @include mq($until: small) {
    padding: 0;
    width: 100%;
  }
}

.section--small {
  padding: 25px;
}

.section--tinted {
  background-color: $color-grey-light;
}

.section--dark {
  background-color: $color-grey-light;
}

.section--has-bg-image {
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
}

.ie .section--has-bg-image .container {
  margin: 0;
}

.section--bgOrigin-center {
  background-position: center;
}

.section--bgOrigin-bottom {
  background-position: bottom;
}

.section--bgOrigin-top {
  background-position: top;
}

.section--bgOrigin-left {
  background-position: left;
}

.section--bgOrigin-right {
  background-position: right;
}

.section--textWhite {
  color: $color-white;

  @include headings {
    color: $color-white;
  }
}

.section--textWhite .wp-block-button__link {
  color: $color-white;
}

.section--topSpacing {
  margin-top: 20px;
}

.section--bottomSpacing {
  margin-bottom: 20px;
}

.section .container > *,
.article-content > * {
  margin-bottom: 0;
}

.section .container > *:not(style) + *,
.article-content > * + * {
  margin-top: 24px;
}

.section--no-padding {
  padding: 0;
}

[data-type="amnesty-core/block-row"],
[data-type="amnesty-core/block-section"] {
  width: 100% !important;
  max-width: none !important;
}

.section--has-bg-gradient {
  position: relative;
}

.section--has-bg-gradient::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
  display: block;
  background: linear-gradient(to right, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, 0) 100%);
}

.section--has-bg-gradient > * {
  z-index: 2;
}
