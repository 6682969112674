.wp-block-amnesty-core-background-media {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include mq(small) {
    width: 100%;
    flex-direction: row;
  }

  .mobile-hide-panel .text-media--backgroundImage {
    @include mq($until: small) {
      display: none !important;
    }
  }
}

.wp-block-amnesty-core-background-media .text-media--itemContainer {
  display: flex;
  width: 100%;
  padding: 24px;
  min-height: 400px;
  position: relative;
  padding-bottom: 40px;
  word-break: break-word;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
}

.wp-block-amnesty-core-background-media .text-media--itemContainer > * {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.wp-block-amnesty-core-background-media .text-media--itemContainer > .image-caption {
  width: auto;
}

.text-media--itemContainer.is-vertically-aligned-center {
  flex-direction: column;
  justify-content: center;
}

.text-media--itemContainer.alignleft {
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  .rtl & {
    align-items: flex-end;
    text-align: right;
  }
}

.text-media--itemContainer.alignright {
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  .rtl & {
    align-items: flex-start;
    text-align: left;
  }
}

.text-media--itemContainer.aligncenter {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wp-block-amnesty-core-background-media.has-imageRight {
  div:nth-child(1) {
    @include mq($from: small, $until: medium) {
      width: 66%;
    }
  }
}

.wp-block-amnesty-core-background-media.has-imageLeft {
  div:first-child {
    @include mq($from: small, $until: medium) {
      width: 66%;
    }
  }
}

.wp-block-amnesty-core-background-media.has-textShadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1), 0 0 .75em rgba(0, 0, 0, 1), 0 0 1.25em rgba(0, 0, 0, 1);
}
