.post-type-archive-petition .actionBlock-content div {
  margin-bottom: 16px;
  font-weight: normal;
}

.categoryTerm-title {
  display: flex;
  align-content: center;
  padding: 30px 0 10px 0;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;

  h1 {
    text-transform: uppercase;
  }
}

.archive-slider {
  margin-top: 24px;
}
