.article {
  flex: 1 1 100%;
  width: 100px;
  max-width: 100%;
  order: 1;

  @include mq(medium-sm) {
    flex: 1 1 calc(100% - 88px);
    order: 2;
  }
}

.article-container {
  @include flexy-wrapper;

  @include mq(large) {
    flex-wrap: nowrap;
  }
}

.article-container--report {
  margin-top: 80px;
}

.article-container--report .article-content {
  padding-top: 30px;
  border-top: 1px solid $color-grey-sm-light;
}

.article.has-sidebar {
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%;
  margin-right: auto;

  @include mq(medium-sm) {
    flex: 1 1 calc(100% - 88px);
    order: 2;
  }

  @include mq(large) {
    flex: 0 1 900px;
    width: 900px;
    max-width: 900px;
  }

  .rtl & {
    margin-right: 0;
    margin-left: auto;
  }
}

.article-figure {
  position: relative;
  margin-bottom: 32px;
  background-color: $color-grey-sm-light;
  text-align: center;

  &.is-stretched img {
    min-width: 100%;
  }
}

.article-title {
  margin-bottom: 0;
}

.article-termWrapper {
  background: $color-black;
  padding: 8px 10px;
  color: $color-white;
}

.article-attachmentTitle {
  font-size: 42px;
  margin-top: 54px;
}

.article-attachmentMeta {
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin-bottom: spacing();
  padding: 8px 0;
  font-size: 14px;

  * + *::before,
  .rtl & * + *::after {
    content: var(--list-separator);
  }

  .rtl & * + * {
    &::before {
      content: "";
    }
  }
}

.article-content > h4,
.article-content > h6 {
  color: $color-grey-base;
}

.article-content a {
  &:active,
  &:enter {
    text-decoration: none;
  }
}

.article-content a:focus-within {
  text-decoration: none;
}

.article-content .section:not(.section--tinted):not(.section--has-bg-image) {
  padding: 0;
}

.article-content .btn {
  text-decoration: none;
}
