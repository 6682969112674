.underlined-menu-item span,
.donate-now-menu-item span {
  position: relative;
}

.underlined-menu-item span::after,
.donate-now-menu-item span::after {
  position: absolute;
  content: "";
  margin-top: 5px;
  display: block;
  width: 100%;
  height: 5px;
  background-color: $color-black;
}

.is-shaded .underlined-menu-item span::after,
.is-shaded .donate-now-menu-item span::after {
  background-color: $color-white;
}
