.linksWithIcons-group {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  @include mq(medium-sm) {
    justify-content: space-around;
    align-items: stretch;
  }
}

.linksWithIcons-group.has-background {
  padding: 12px 0;
}

.linksWithIcons-group.has-1-items {
  padding: 0;
}

.linksWithIcons-group.has-1-items.has-background {
  padding: 60px 0;
}

.linksWithIcons-group .linksWithIcons {
  flex-basis: 100%;
}

.linksWithIcons-group.is-vertical {
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 456px;
}

.linksWithIcons-group.is-vertical .linksWithIcons,
.linksWithIcons-group.is-vertical .linksWithIcons-spacer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: auto;
  align-self: center;
}

.linksWithIcons-group.is-horizontal:not(.is-style-square) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.linksWithIcons-group.is-horizontal .linksWithIcons {
  @include mq(medium-sm) {
    flex-basis: 33%;

    &:nth-child(5) {
      margin-top: 0;
    }
  }
}

.linksWithIcons-group.has-2-items .linksWithIcons {
  @include mq(medium-sm) {
    flex-basis: 49%;
  }
}

.linksWithIcons-group.has-3-items .linksWithIcons {
  @include mq(medium-sm) {
    flex-basis: 33%;
  }
}

.linksWithIcons-group.is-horizontal.has-4-items .linksWithIcons {
  @include mq(x-small) {
    padding: 0 5px;

    &:nth-child(7) {
      margin-top: 60px;
    }
  }

  @include mq(medium-sm) {
    flex-basis: 24%;

    &:nth-child(7) {
      margin-top: 0;
    }
  }
}

.rowColumn .linksWithIcons-spacer {
  @include mq(medium-sm) {
    display: none !important;
  }
}

.linksWithIcons-group.icon-none .linksWithIcons-spacer::before
.linksWithIcons-group.icon-none .linksWithIcons-spacer::after {
  display: none !important;
}

.linksWithIcons-group.icon-arrow .linksWithIcons-spacer,
.linksWithIcons-group.icon-ampersand .linksWithIcons-spacer {
  position: relative;
}

.linksWithIcons-group.icon-arrow.is-horizontal .linksWithIcons-spacer::before {
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 313px 300px;
  background-position: -10px -162px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  display: block;
  background-color: $color-black;
}

.linksWithIcons-group.icon-arrow.is-horizontal .linksWithIcons-spacer::after {
  pointer-events: none;
  background-image: url("../images/sprite.svg"), none;
  background-repeat: no-repeat;
  background-size: 313px 300px;
  background-position: -10px -162px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  display: block;

  @include mq(medium-sm) {
    transform: rotate(-90deg);

    .rtl & {
      transform: rotate(90deg);
    }
  }
}

.linksWithIcons-group.icon-arrow.is-vertical .linksWithIcons-spacer::before {
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 313px 300px;
  background-position: -10px -162px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  display: block;
  background-color: $color-black;
}

.linksWithIcons-group.icon-arrow.is-vertical .linksWithIcons-spacer::after {
  pointer-events: none;
  background-image: url("../images/sprite.svg"), none;
  background-repeat: no-repeat;
  background-size: 313px 300px;
  background-position: -10px -162px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  display: block;
}

.linksWithIcons-group.icon-ampersand .linksWithIcons-spacer::before {
  pointer-events: none;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  z-index: 1;
  width: 40px;
  height: 40px;
  content: "";
  position: absolute;
  display: block;
  background: $color-black;
  color: $color-white;
}

.linksWithIcons-group.icon-ampersand .linksWithIcons-spacer::after {
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-weight: bold;
  font-style: italic;
  z-index: 1;
  font-size: 30px;
  content: "\0026";
  position: absolute;
  display: block;
  color: $color-white;

  .rtl & {
    transform: translateY(-50%) translateX(-50%) rotateY(180deg);
  }
}

.linksWithIcons-group.has-very-light-gray-background-color {
  background-color: $color-grey-light;
}

.linksWithIcons-group.is-style-square {
  display: grid;
  column-gap: spacing();
  row-gap: spacing();
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;

  @include mq(x-small) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq(large) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.linksWithIcons-group.is-style-square.linksWithIcons-group.has-1-items,
.linksWithIcons-group.is-style-square.linksWithIcons-group.has-2-items,
.linksWithIcons-group.is-style-square.linksWithIcons-group.has-3-items {
  @include mq(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}
