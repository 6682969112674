.author-banner {
  position: relative;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: flex-end;

  @include mq(large) {
    text-align: unset;
  }
}

.author-header-container {
  display: flex;
  align-items: center;
  padding: 20px 20px 35px 20px;
  width: 100%;
  flex-direction: column;

  @include mq(large) {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
}

.author-avatar {
  max-width: 150px;
  margin-bottom: 10px;

  @include mq(large) {
    padding-right: 20px;
    margin-bottom: unset;
  }
}

.author-info-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;

  @include mq(large) {
    align-items: flex-start;
  }
}

.author-page-twitter {
  color: $color-white;
  font-family: var(--font-family-secondary);
  font-weight: 300;
  text-align: center;

  &::before {
    content: " ";
    display: inline-block;
    transform: scale(.6);
    vertical-align: -10%;
    pointer-events: none;
    background-image: url("../images/sprite.svg"), none;
    background-repeat: no-repeat;
    background-size: 313px 353px;
    width: 20px;
    height: 17px;
    background-position: -246px -82px;
  }

  @include mq(large) {
    text-align: left;
  }
}

.author-display-name {
  color: $color-white;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.author-description {
  display: none;
  color: $color-black;
  font-family: var(--font-family-primary);
  margin-bottom: 10px;
  padding: 5%;
  text-align: center;

  @include mq(large) {
    display: block;
    color: $color-white;
    padding: unset;
    text-align: left;
  }
}

.author-content {
  margin-right: 0;
  width: 100%;

  @include mq(large) {
    margin-right: 10%;
  }
}

.author-biography {
  color: $color-black;
  margin-bottom: 24px;
}

.twitter-sidebar {
  order: 2;
  padding-left: 12px;
}

.author .article-shareContainer {
  margin-right: 10% !important;
}

.author-post-feed {
  padding: 20px;
}

.is-collapsed::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 150px, #fff);
}

.is-collapsed {
  padding-bottom: 5px;
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  content: "";
  position: relative;
}

.biography-container {
  margin-bottom: 24px;
}

.author-banner-caption {
  background-color: rgba($color-black, .1);
  padding: 5px;
  font-size: 15px;
  font-style: italic;
  color: $color-white;
  text-align: center;

  @include mq(large) {
    font-size: 10px;
    font-style: unset;
    text-align: unset;
  }
}

.caption-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;

  @include mq(large) {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    min-width: 50%;
    max-width: 50%;

    .rtl & {
      right: auto;
      left: 0;
    }
  }
}
