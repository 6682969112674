.tax-location .page-hero {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.tax-location .page-hero .image-caption {
  left: 0;
  margin: 0 auto;
  max-width: $flexy-container;
  background-color: transparent;
  text-align: right;
  color: #000;
}

.tax-location.report .article-sidebar {
  @include mq(large) {
    margin-top: 0 !important;
  }
}

.tax-location .customSelect {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  @include mq(small) {
    flex-direction: row;
  }
}

.tax-location .customSelect-label {
  margin-right: spacing();
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
}

.tax-location.rtl .customSelect-label {
  margin-right: 0;
  margin-left: spacing();
}

.officeList {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include mq(small) {
    flex-direction: row;
  }
}

.contactInfo {
  margin: 0 0 spacing();
  display: block;
}

.officeList + .contactInfo {
  margin-bottom: 0;
  padding-top: spacing();
  border-top: 1px solid $color-grey-light;
}

.contactInfo dt h4 {
  margin-bottom: 0;
  font-size: map-get($desktop-header-sizes, "h5");
}

.contactInfo dd p {
  font-weight: normal;
  white-space: pre-line;

  &:last-child {
    margin-bottom: 0;
  }
}

.contactInfo dd a {
  text-decoration: none;
}

.contactInfo dd + dt {
  margin-top: spacing(half);
}

.countryLinks {
  @extend .btn; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
  margin: 0 spacing(half) spacing(half);
  padding: spacing(half) spacing();
  background-color: $color-grey-xx-light;
  border-color: $color-grey-xx-light;
  font-size: 24px;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-grey-light;
    border-color: $color-grey-light;
  }
}

.human-rightsStatus {
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;

  @include mq(small) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.human-rights {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 25px;
  width: 100%;
  background-color: $color-grey-xx-light;
  background-position: bottom right;
  background-repeat: no-repeat;
  text-align: center;
}

.human-rightsAnswer {
  text-transform: uppercase;
  font-size: 38px;

  @include mq(small) {
    font-size: 58px;
  }
}

.human-rightsDescription {
  font-size: 14px;
}

.tax-location .container {
  padding-left: 0;
  padding-right: 0;
}

.officeList.multipleOffices {
  flex-direction: column;
}
