@mixin spacer-horizontal {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 35%;
  height: 2px;
  background-color: rgba($color-black, .3);

  @include mq(x-small) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@mixin spacer-vertical {
  margin-top: auto;
  margin-bottom: auto;
  width: 2px;
  height: 200px;
  background-color: rgba($color-black, .3);
}

.linksWithIcons-group .linksWithIcons-spacer:last-child {
  display: none !important;
}

.linksWithIcons-group.is-vertical .linksWithIcons-spacer {
  @include spacer-horizontal;
}

.linksWithIcons-group.is-horizontal .linksWithIcons-spacer {
  @include spacer-horizontal;

  @include mq(medium-sm) {
    @include spacer-vertical;

    &:nth-child(4) {
      display: block;
    }
  }
}

.linksWithIcons-group.has-no-lines .linksWithIcons-spacer {
  display: none !important;
}
