@font-face {
  font-family: "Amnesty Trade Gothic";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/TradeGothicLTW05-Light.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-Light.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-Light.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-Light.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Amnesty Trade Gothic";
  font-display: swap;
  font-style: oblique;
  font-weight: 300;
  src: url("../fonts/TradeGothicLTW05-LightObl.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-LightObl.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-LightObl.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-LightObl.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-LightObl.ttf") format("truetype");
}

@font-face {
  font-family: "Amnesty Trade Gothic";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/TradeGothicLTW05-Roman.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-Roman.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-Roman.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-Roman.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Amnesty Trade Gothic";
  font-display: swap;
  font-style: oblique;
  font-weight: normal;
  src: url("../fonts/TradeGothicLTW05-Oblique.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-Oblique.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-Oblique.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-Oblique.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "Amnesty Trade Gothic Condensed";
  font-display: swap;
  font-style: oblique;
  font-weight: normal;
  src: url("../fonts/TradeGothicLTW05-CnNo.18Obl.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-CnNo.18Obl.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-CnNo.18Obl.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-CnNo.18Obl.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-CnNo.18Obl.ttf") format("truetype");
}

@font-face {
  font-family: "Amnesty Trade Gothic Condensed";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/TradeGothicLTW05-BdCnNo.20.eot?#iefix");
  src: url("../fonts/TradeGothicLTW05-BdCnNo.20.eot?#iefix") format("eot"),
    url("../fonts/TradeGothicLTW05-BdCnNo.20.woff2") format("woff2"),
    url("../fonts/TradeGothicLTW05-BdCnNo.20.woff") format("woff"),
    url("../fonts/TradeGothicLTW05-BdCnNo.20.ttf") format("truetype");
}
