.article-shareContainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  order: 2;
  margin: 24px auto 0 !important;

  @include mq(medium-sm) {
    order: 1;
    margin-top: 10px !important;
    margin-right: 16px !important;
    margin-left: 16px !important;
    max-width: 56px;
    flex: 0 0 56px;

    .single-post & {
      margin-top: 0 !important;
    }
  }
}

.article-shareContainer:empty {
  margin-top: 0;
  margin-bottom: 0;
}

.article-shareContainer--absolute {
  margin-top: 20px;
  margin-left: 10px;
  max-width: 100%;

  .article-share ul {
    display: flex;
  }

  .article-share li + li {
    border-left: 1px solid $color-white;
    border-top: none;
  }

  @include mq(medium) {
    margin-top: 60px;
  }

  @include mq(medium-lg) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 56px;

    .article-share ul {
      display: block;
    }

    .article-share li + li {
      border-left: none;
      border-top: 1px solid $color-white;
    }
  }
}

.section--small:first-child + .article-shareContainer--absolute {
  margin-top: 20px;

  @include mq(medium) {
    margin-top: 20px;
  }
}

.article-share {
  position: static;
  top: 20px;
  max-width: 113px;
  margin: 0 auto;
  transition: top .3s ease-in-out;

  @include mq(medium-sm) {
    position: sticky;
  }
}

.admin-bar .article-share {
  top: 66px;

  @include mq(wp-adminbar) {
    top: 52px;
  }
}

.article-shareTitle {
  margin-bottom: 0;
  font-size: 18px;
  font-family: var(--font-family-secondary);
  text-transform: uppercase;
  text-align: center;
  line-height: 1.44;
  color: $color-white !important;
  background-color: $color-black;
  border-bottom: 1px solid $color-grey-light;
}

.article-share ul {
  display: flex;
  margin-left: 0;
  padding: 0 !important;
  list-style: none;

  @include mq(medium-sm) {
    display: block;
  }
}

.article-share a {
  @include state-background(lighten($color-black, 20%));
  display: block;
  padding: 12px;
  width: 56px;
  height: 56px;
  text-align: center;
  background-color: $color-black;
}

.article-share ul li + li {
  border-left: 1px solid $color-grey-light;

  .rtl & {
    border-right: 1px solid $color-grey-light;
    border-left: none;
  }

  @include mq(medium-sm) {
    border-top: 1px solid $color-grey-light;
    border-left: none;

    .rtl & {
      border-right: none;
    }
  }
}

.article-share img {
  max-height: 100%;
}
