.wp-block-amnesty-core-link-group {
  padding: 24px;
  background-color: $color-grey-x-light;
}

.wp-block-amnesty-core-link-group h2 {
  margin-bottom: spacing();
  font-size: map-get($desktop-header-sizes, "h4");
}

.wp-block-amnesty-core-link-group ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wp-block-amnesty-core-link-group li {
  display: flex;
  background-color: $color-white;
  word-break: break-word;
}

.wp-block-amnesty-core-link-group li + li {
  margin-top: spacing(half);
}

.wp-block-amnesty-core-link-group li a {
  display: flex;
  padding: spacing(half);
  width: 100%;
  height: 100%;
  transition: background .3s ease-in-out;
  text-decoration: none;
}

.wp-block-amnesty-core-link-group li a:hover,
.wp-block-amnesty-core-link-group li a:active,
.wp-block-amnesty-core-link-group li a:focus {
  background-color: $color-grey-light;
}

.wp-block-amnesty-core-link-group li a > svg {
  margin-right: spacing(half);
  width: 20px;

  .rtl & {
    margin-right: 0;
    margin-left: spacing(half);
  }
}
