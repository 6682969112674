.wp-block-file {
  display: flex;
  align-items: center;

  .page-template-without-sidebar main &,
  .post-template-without-sidebar main & {
    margin-right: auto;
    margin-left: auto;
    max-width: 850px;
  }
}

.wp-block-file a.wp-block-file__button {
  margin-left: 10px;
}
