.burger {
  display: inline-flex;
  align-items: center;
  padding: 20px 15px;
  height: 100%;
  border: 2px solid transparent;
  font-family: var(--font-family-secondary);
  background-color: transparent;
  text-transform: uppercase;
  transition: background-color .2s ease-in-out;
  cursor: pointer;

  @include mq(small) {
    padding: 20px;
  }

  @include mq(xm-large) {
    display: none;
  }
}

.burger:active,
.burger:focus {
  border: 2px solid $color-orange-base;
}

.burger span,
.burger span::before,
.burger span::after {
  display: block;
  width: 27px;
  height: 4px;
  pointer-events: none;
}

.burger span {
  position: relative;
  background-color: $color-black;
  transition: background-color .2s ease-in-out;
}

.burger span::before,
.burger span::after {
  content: "";
  position: absolute;
  left: 0;
  background-color: inherit;
}

.burger span::before {
  top: -10px;
}

.burger span::after {
  bottom: -10px;
}
