.social-sharePattern .wp-block-columns {
  display: grid;
  column-gap: 24px;
  row-gap: 50px;
  align-items: stretch;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr auto;
  width: 100%;

  @include mq(small) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.social-sharePattern .wp-block-column {
  display: grid;
  align-items: stretch;
  justify-items: center;
  margin: unset;
}

.social-sharePattern .wp-block-column:first-child {
  // centre of row one
  @include mq(small, large) {
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.social-sharePattern .wp-block-column:nth-child(2) {
  // start of row 2
  @include mq(small, large) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.social-sharePattern .wp-block-column:nth-child(3) {
  // end of row 2
  @include mq(small, large) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

.social-sharePattern .wp-block-column + .wp-block-column {
  padding-top: 24px;
  border-top: 1px solid $color-grey-light;

  @include mq(small) {
    padding-top: 0;
    padding-left: 24px;
    border-top: none;
  }

  @include mq(large) {
    border-left: 1px solid $color-grey-light;
  }
}

.rtl .social-sharePattern .wp-block-column + .wp-block-column {
  @include mq(small) {
    padding-right: 24px;
    padding-left: 0;
  }

  @include mq(large) {
    border-right: 1px solid $color-grey-light;
    border-left: none;
  }
}

.social-sharePattern .tweetAction.is-style-alternative-style {
  display: grid;
}

.social-sharePattern .wp-block-column .wp-block-button.is-style-dark a {
  background-color: $color-blue-dark;
}

.social-sharePattern .wp-block-buttons {
  align-self: flex-end;
}

.social-sharePattern .has-text-align-center {
  width: 100%;
}

.social-sharePattern .wp-embed-responsive {
  width: 80%;
  max-width: 80%;
}

.social-sharePattern .wp-block-button__link {
  min-width: 230px;
}

.social-sharePattern .wp-social-link svg {
  transform: scale(1.5);
}

.social-sharePattern .wp-social-link {
  height: 50px;
  width: 50px;
  margin-right: 20px;
}

.section.social-sharePattern .wp-social-link a {
  padding: 12px;
  margin-top: 0;
}

.social-sharePattern .wp-block-buttons .wp-block-button {
  margin-bottom: 0;
}
