.article-meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .btn {
    margin-right: 12px;

    .rtl & {
      margin-right: 0;
      margin-left: 12px;
    }
  }
}

.postDate {
  font-family: var(--font-family-primary);
  font-weight: 300;
}

.article-docref {
  margin: 24px 0;
  padding: 10px 0;
  border-top: 1px solid $color-grey-sm-light;
  border-bottom: 1px solid $color-grey-sm-light;
}

.parentTerm-link {
  display: block;
  margin-bottom: 32px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--font-family-secondary);
}

.parentTerm-link a + a {
  margin-left: 16px;

  .rtl & {
    margin-right: 16px;
    margin-left: 0;
  }
}
