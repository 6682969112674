.select2-container .select2-selection--single {
  border-radius: 0;
  border: 1px solid $color-black;
  padding: 12px;
  height: auto;
  font-family: var(--font-family-secondary);
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-transform: uppercase;
  color: $color-grey-base;
  opacity: 1;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-weight: bold;
  line-height: normal;
  color: $color-black;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: $color-primary;
  color: $color-black;
}

.select2-results__option {
  font-family: var(--font-family-secondary);
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  color: $color-grey-base;
}
