body.search main,
body.page-template-searchpage main {
  margin-top: spacing();
  margin-bottom: spacing();
}

body.search .section,
body.page-template-searchpage .section {
  padding: spacing();
}

body.search .post-pagination,
body.page-template-searchpage .post-pagination {
  padding-bottom: 0;
}

body.search .section .page-search,
body.page-template-searchpage .section .page-search {
  flex-direction: column;
  background-color: unset;

  @include mq(xx-small) {
    flex-direction: row;
  }
}

body.search .section .page-search input,
body.page-template-searchpage .section .page-search input {
  margin-bottom: 10px;

  @include mq(xx-small) {
    margin-bottom: 0;
  }
}

.post--result {
  @include state-background($color-grey-light);
  position: relative;
}

.search-container {
  display: flex;
  flex-direction: column;
  margin-top: spacing();

  @include mq(medium) {
    flex-direction: row;
  }
}

.search-filters {
  flex-basis: 100%;
  margin-bottom: spacing();

  @include mq(medium) {
    flex-basis: 25%;
    margin-right: spacing();
    margin-bottom: 0;

    .rtl & {
      margin-right: 0;
      margin-left: spacing();
    }
  }
}

.search-filters select {
  width: 100%;
}

.advanced-search-results .section {
  padding: spacing();
}

.search-results {
  flex-basis: 75%;
}

.search-filters .btn {
  width: 100%;
}

.search-container .active-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.search-container .active-filters a {
  margin-bottom: 10px;
}

body.search .search-filters .section.filter-wrapper {
  padding: 0;
  margin-bottom: 24px;
}

// i died a little writing this rule
.search-filters .taxonomyArchive-filters {
  flex-direction: column;
}

.search-filters .checkbox-dropdownList-trigger {
  margin-bottom: 24px;
  width: 100%;
}
