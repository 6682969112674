.article-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin: 48px 0 0;
  order: 3;

  @include mq(large) {
    align-items: flex-start;
    flex: 1 1 392px;
    width: 392px;
    max-width: 392px;
    margin: 0 0 0 16px;
  }
}

.article-sidebar:empty {
  margin-top: 0;
  margin-bottom: 0;
}

.article-sidebar:not(:empty) {
  @include mq(large) {
    margin-top: 86px;
  }
}

.single-post .article-sidebar:not(:empty) {
  @include mq(large) {
    margin-top: 67px;
  }
}

.page .article-sidebar:not(:empty) {
  @include mq(large) {
    margin-top: 6px;
  }
}

.article-sidebar > * {
  margin-bottom: 0;
}

.article-sidebar > * + * {
  margin-top: 24px;
}

.article-sidebar .section {
  margin: 0 auto;
  max-width: 100%;
  padding: 24px;
}

.article-sidebar .linkList li {
  @include mq(large) {
    width: 100%;
  }
}

.article-sidebar .linkList-item {
  min-height: 0;
}

.article-sidebar .linksWithIcons-group.is-vertical {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
