.iframeButton-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.iframeButton-wrapper.is-left-aligned {
  justify-content: flex-start;
}

.iframeButton-wrapper.is-center-aligned {
  justify-content: center;
}

.iframeButton-wrapper.is-right-aligned {
  justify-content: flex-end;
}

.iframeButton-wrapper iframe {
  display: none;
  margin-top: 24px;
  width: 100%;
}

.iframeButton-wrapper.is-visible iframe {
  display: block;
}

.callToAction .iframeButton-wrapper {
  width: 100%;
}
