.page-header .searchform-wrapper {
  position: absolute;
  display: none;
  padding: 24px 0;
  width: 100%;
  background-color: $color-white;
}

.search-active .page-header .searchform-wrapper {
  display: block;
}

.searchform {
  display: flex;
  justify-content: center;
  padding: 24px;
  border: 1px solid $color-grey-light;
  width: 100%;
}

.searchform form {
  display: flex;
  width: 100%;
  max-width: 700px;
}

.searchform .btn {
  margin-left: 24px;

  .rtl & {
    margin-right: 24px;
    margin-left: 0;
  }
}

.searchform.is-advanced .btn {
  margin: 0;
  align-self: flex-start;
}

.searchbox .searchform {
  margin: 0 auto;
  padding: 0;
  border: none;

  @include mq(small) {
    width: 50%;
  }
}

.searchbox .searchform.is-advanced {
  flex-direction: column;
}

.searchbox .searchform.is-advanced .field-split {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
}

.searchbox .searchform.is-advanced .field-split > * {
  width: 49%;
}
