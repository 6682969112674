.woocommerce-table__product-name {
  width: 65%;
}

.woocommerce-table--order-details tfoot tr td:last-child,
.woocommerce-table__product-quantity,
.woocommerce-table__product-total {
  border-left: 1px solid $color-grey-light;
}

.woocommerce table.shop_table tfoot td {
  font-weight: normal;
}

.woocommerce .woocommerce-customer-details address {
  padding: 0;
}

.woocommerce-columns + .woocommerce-columns {
  margin-top: $spacing-unit;
}
