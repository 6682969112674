.icon {
  @include icon-wrapper();
  @include icon-dimensions();
  display: inline-block;
}

.icon-arrow-left {
  @include icon(234px, 240px, 16px, 16px);
  display: inline-block;

  .rtl & {
    @include icon(110px, 265px, 16px, 16px);
  }
}

.icon-remove {
  @include icon(0, 285px, 16px, 16px);
  display: inline-block;

  .remove-link:hover &,
  .remove-link:active &,
  .remove-link:focus & {
    background-position: -20px -285px;
  }
}

.icon-remove-alt {
  @include icon(20px, 285px, 16px, 16px);
  display: inline-block;
}

.icon-document {
  @include icon(26px, 265px, 16px, 16px);
  display: inline-block;
}

.icon-cart {
  @include icon-size(22px, 22px);
}

%icon-cart-dark {
  @include icon-position(284px, 230px);
}

%icon-cart-light {
  @include icon-position(284px, 260px);
}

.icon-search {
  @include icon-size(18px, 18px);
}

%icon-search-dark {
  @include icon-position(128px, 240px);
}

%icon-search-light {
  @include icon-position(105px, 240px);
}

.icon-close {
  @include icon-scaled(194px, 265px, 16px, 16px, 1.5);

  @include mq(small) {
    @include icon-dimensions(2);
    @include icon-size(32px, 32px);
    @include icon-position(388px, 530px);
  }
}

.icon-arrow-down {
  @include icon-dimensions(1.5);
  @include icon-size(24px, 24px);

  @include mq(small) {
    @include icon-dimensions(2);
    @include icon-size(32px, 32px);
  }
}

%icon-arrow-down-dark {
  @include icon-position(406.5px, 322.5px);

  @include mq(small) {
    @include icon-position(542px, 430px);
  }
}

%icon-arrow-down-light {
  @include icon-position(322.5px, 398.25px);

  @include mq(small) {
    @include icon-position(430px, 531px);
  }
}
